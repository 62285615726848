<template>
  <v-container
    id="wizard"
    tag="section"
  >
    <validation-observer v-slot="{ valid }">
      <base-material-wizard
        v-model="tab"
        :available-steps="availableSteps"
        :items="tabs"
        :title="listing.name"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="tab--"
      >
        <v-tab-item class="pb-12">
          <form>
            <v-row
              class="mx-auto"
              justify="space-around"
            >
              <v-col
                cols="12"
                md="12"
                sm="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                >
                  <v-text-field
                    v-model="listing.name"
                    :rules="[nameRules.required]"
                    :error-messages="errors"
                    color="secondary"
                    label="Listing Name"
                    validate-on-blur
                  />
                </validation-provider>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
              <validation-provider
                v-slot="{ errors }"
                name="Address"
              >
                <v-text-field
                  v-model="listing.addressLine1"
                  :error-messages="errors"
                  color="secondary"
                  label="Address Line 1"
                  validate-on-blur
                />
              </validation-provider>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="address2"
                >
                  <v-text-field
                    v-model="listing.addressLine2"
                    :error-messages="errors"
                    color="secondary"
                    label="Address Line 2"
                    validate-on-blur
                  />
                </validation-provider>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="address3"
                >
                  <v-text-field
                    v-model="listing.addressLine3"
                    :error-messages="errors"
                    color="secondary"
                    label="Address Line 3"
                    validate-on-blur
                  />
                </validation-provider>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="city"
                >
                  <v-text-field
                    v-model="listing.city"
                    :error-messages="errors"
                    color="secondary"
                    label="City"
                    validate-on-blur
                  />
                </validation-provider>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="listing.postCode"
                  :rules="[postcodeRules.postcode]"
                  color="secondary"
                  label="Postcode"
                  validate-on-blur
                  hint="Postcode must be upper case."
                  @input="formatPostcode"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="county"
                >
                  <v-text-field
                    v-model="listing.county"
                    :error-messages="errors"
                    color="secondary"
                    label="county"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="country"
                >
                  <v-text-field
                    v-model="listing.country"
                    :error-messages="errors"
                    color="secondary"
                    label="Country"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                  <v-text-field
                    v-model="social.phone"
                    color="secondary"
                    label="Phone Number"
                    validate-on-blur
                  />
              </v-col>
              <v-col cols="12" md="6">
                  <v-text-field
                    v-model="social.email"
                    :rules="[emailRules.email]"
                    color="secondary"
                    label="Email"
                    validate-on-blur
                  />
              </v-col>
            </v-row>
          </form>
        </v-tab-item>

        <v-tab-item class="pb-12">
          <form>
            <v-responsive
              class="mx-auto"
            >
              <div class="text-center display-2 font-weight-light mb-6">
                Category, Tags, Summary and Description
              </div>
              <div class="text-left display-2 grey--text font-weight-light mb-6">
                Listing Tags
              </div>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    class="mx-auto"
                    v-model="listing.category"
                    :items="categoryOptions"
                    item-text="tagName"
                    item-value="id"
                    label="Select a category."
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="listing.superTag"
                    :items="superTagOptions"
                    :loading="isSuperLoading"
                    :search-input.sync="searchSuperTag"
                    outlined
                    chips
                    small-chips
                    deletable-chips
                    item-text="tagName"
                    item-value="id"
                    hide-no-data
                    label="Available Super Tags"
                    placeholder="Start typing to Search"
                    append-icon="mdi-database-search"
                    v-on:input="searchSuperTag = null"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeSuperTag(data.item)"
                      >
                        {{ data.item.tagName }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.tagName"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="listing.tags"
                    :items="tagOptions"
                    :loading="isLoading"
                    :search-input.sync="searchTags"
                    outlined
                    chips
                    small-chips
                    deletable-chips
                    item-text="tagName"
                    item-value="id"
                    hide-no-data
                    multiple
                    label="Available Tags"
                    placeholder="Start typing to Search"
                    return-object
                    append-icon="mdi-database-search"
                    v-on:imput="searchTags = null"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeTag(data.item)"
                      >
                        {{ data.item.tagName }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.tagName"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    class="mx-auto"
                    v-model="listing.displayTag"
                    :items="listing.tags"
                    item-text="tagName"
                    item-value="id"
                    label="Select a display tag"
                    outlined
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
              <div class="text-left display-2 grey--text font-weight-light mb-6">
                Listing Content
              </div>
              <v-col cols="12">
                <v-textarea outlined max-length="300" :counter="300" v-model="listing.summary" :rules="[summaryRules.required, summaryRules.maxLength]" label="Summary"></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea outlined max-length="1200" :rules="[contentRules.length]" :counter="1200" v-model="listing.content" label="Content"></v-textarea>
              </v-col>
            </v-responsive>
          </form>
        </v-tab-item>

        <v-tab-item class="pb-12">
          <form>
            <v-row
              class="mx-auto"
              justify="space-around"
            >
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  solo
                  v-model="social.website"
                  color="blue-grey"
                  label="Website"
                  prepend-inner-icon="mdi-web"
                  validate-on-blur
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  solo
                  v-model="social.facebook"
                  color="blue darken-3"
                  label="Facebook"
                  prepend-inner-icon="mdi-facebook"
                  validate-on-blur
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  solo
                  v-model="social.twitter"
                  color="cyan darken-1"
                  label="Twitter"
                  prepend-inner-icon="mdi-twitter"
                  validate-on-blur
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  solo
                  v-model="social.instagram"
                  color="purple lighten-2"
                  label="Instagram"
                  prepend-inner-icon="mdi-instagram"
                  validate-on-blur
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  solo
                  v-model="social.linkedIn"
                  color="indigo"
                  label="linkedIn"
                  prepend-inner-icon="mdi-linkedin"
                  validate-on-blur
                />
              </v-col>
            </v-row>
          </form>
        </v-tab-item>

        <v-tab-item class="pb-12">
          <form>
            <fileUpload @imageUploaded="emitImage" :obj="image" fieldName="thumbnail" fileType="image/png, image/jpeg" :imageUrl="listing.imageUrl" :directory="imageBucket" label="Select a Image" />
            <v-row align="center" justify="center">
              <v-img v-if="listing.imageUrl" :src="listing.imageUrl" width="368" height="150" contain></v-img>
            </v-row>
          </form>
        </v-tab-item>
        <v-tab-item class="pb-12">
          <v-row justify="center" align="center" style="margin: 0px !important">
            <h4>Select business hours and then confirm to add them to the listing! Or, skip this step and finish the listing!</h4>
          </v-row>
          <form>
            <OpenHours @hoursChanged="saveBusinessHours" :enableHours="enableHours" :hoursOfBusiness="listing.hoursOfBusiness" />
          </form>
        </v-tab-item>
      </base-material-wizard>
    </validation-observer>
    <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
      <v-progress-circular
        v-if="snackColor === 'info'"
        color="white"
        indeterminate
      ></v-progress-circular>
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import listingDetails from '@/Services/Listings/ListingDetails'
import listingManagement from '@/Services/Listings/ListingManagement'
// import Cropper from 'cropperjs'
// import debounce from 'lodash/debounce'
import updateListing from '@/Services/Listings/UpdateListing'
import fileUpload from '@/views/dashboard/component/fileUpload'
import OpenHours from '@/views/dashboard/component/OpenHours'
export default {
  name: 'DashboardFormsWizard',
  components: {
    fileUpload,
    OpenHours
  },
  data () {
    return {
      imageBucket: process.env.VUE_APP_AWSIMAGEBUCKETLISTINGS,
      snack: false,
      snackColor: '',
      snackText: '',
      snackTimeout: null,
      enableHours: false,
      listing: {},
      categoryOptions: [],
      tagOptions: [],
      superTagOptions: [],
      searchTags: '',
      searchSuperTag: '',
      isLoading: false,
      isSuperLoading: false,
      category: '',
      fieldName: '',
      content: '',
      contentRules: {
        length: v => (v && v.length <= 1200) || 'Content must be less than 1200 characters'
      },
      email: '',
      emailRules: {
        email: v => {
          if (v) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(v) || 'Invalid e-mail.'
          }
        }
      },
      image: Object,
      phone: '',
      postcodeRules: {
        postcode: v => {
          if (v) {
            const pattern = /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/
            return pattern.test(v) || 'Invalid Postcode'
          }
        }
      },
      social: {
        phone: null,
        mobile: null,
        email: null,
        website: null,
        facebook: null,
        twitter: null,
        instagram: null,
        linkedIn: null
      },
      summaryRules: {
        required: v => !!v || 'Required',
        maxLength: v => (v && v.length <= 300) || 'Summary must be less than 300 characters'
      },
      nameRules: {
        required: v => !!v || 'Required'
      },
      tab: 0,
      tabs: ['Contact Details', 'Summary & Content', 'Web & Social', 'Image', 'Business Hours']
    }
  },
  created () {
    this.listingID = this.$route.params.id
    this.initialise(this.listingID)
  },
  computed: {
    scope () {
      if (this.tab === 0) return 'Contact Details'
      else if (this.tab === 4) return 'Business Hours'
      return 'listing'
    },
    availableSteps () {
      const steps = [0]

      let validPostcode = null

      if (this.listing.postCode) {
        if (this.postcodeRules.postcode(this.listing.postCode) !== 'Invalid Postcode') {
          validPostcode = true
        }
      }

      let validEmail = null

      if (this.emailRules.email(this.social.email) !== 'Invalid e-mail.') {
        validEmail = true
      }

      if (this.listing.postCode && validPostcode) {
        if ((this.social.phone || (this.social.email && validEmail)) && this.listing.name) {
          steps.push(1)
        }
      } else if ((this.social.phone || (this.social.email && validEmail)) && this.listing.postCode.length === 0 && this.listing.name) {
        steps.push(1)
      }

      let validSummary = null

      if (this.summaryRules.maxLength(this.listing.summary) !== 'Summary must be less than 300 characters') {
        validSummary = true
      }

      if (this.listing.category && this.listing.tags && (this.listing.summary && validSummary) && steps.includes(1)) steps.push(2)

      if (
        steps.includes(2)
      ) steps.push(3)

      if (
        steps.includes(3)
      ) steps.push(4)

      if (
        steps.includes(4)
      ) steps.push(5)

      return steps
    }
  },

  watch: {
    async searchTags (val) {
      // Items have already been loaded
      if (this.tagOptions.length > 0) return
      // Items have already been requested
      if (this.isLoading) return
      this.isLoading = true
      // Lazily load input items
      await listingManagement.getTags()
        .then((res) => {
          if (res.status === 200) {
            this.tagOptions = res.data
            this.isLoading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load tags. Error: ' + res.data.title
            this.snackTimeout = 5000
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err)
        })
    },
    async searchSuperTag (val) {
      // Items have already been loaded
      if (this.superTagOptions.length > 0) return
      // Items have already been requested
      if (this.isSuperLoading) return
      this.isSuperLoading = true
      // Lazily load input items
      await listingManagement.getSuperTags()
        .then((res) => {
          if (res.status === 200) {
            this.superTagOptions = res.data
            this.isSuperLoading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load super tags. Error: ' + res.data.title
            this.snackTimeout = 5000
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err)
        })
    }
  },

  methods: {
    formatPostcode () {
      this.listing.postCode = this.listing.postCode.toUpperCase()
    },
    emitImage (e) {
      this.listing.imageUrl = e
    },
    async initialise (id) {
      await listingDetails.getDetails(id)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'listing', response.data)
            if (this.listing.contactDetails.length >= 1) {
              this.listing.contactDetails.forEach(element => {
                switch (element.contacttypeid) {
                  case 1:
                    this.social.phone = element.contactdetail
                    break
                  case 2:
                    this.social.mobile = element.contactdetail
                    break
                  case 3:
                    this.social.email = element.contactdetail
                    break
                  case 4:
                    this.social.website = element.contactdetail
                    break
                  case 5:
                    this.social.facebook = element.contactdetail
                    break
                  case 6:
                    this.social.twitter = element.contactdetail
                    break
                  case 7:
                    this.social.instagram = element.contactdetail
                    break
                  case 8:
                    this.social.linkedIn = element.contactdetail
                    break
                  default:
                    // eslint-disable-next-line
                    console.warn('No contact types found')
                }
              })
            }
            if (this.listing.hoursOfBusiness.length >= 1) {
              this.enableHours = true
            }
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to retrieve listing details. Error: ' + response.data.title
            this.snackTimeout = 5000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
      await listingManagement.getCat()
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'categoryOptions', response.data)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load categories.'
            this.snackTimeout = 3000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
    },
    async next (valid) {
      this.listing.contactDetails = []
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Loading...'
      this.snackTimeout = -1
      if (this.social.phone) {
        this.listing.contactDetails.push({ contacttypeid: 1, contactdetail: this.social.phone })
      }
      if (this.social.mobile) {
        this.listing.contactDetails.push({ contacttypeid: 2, contactdetail: this.social.mobile })
      }
      if (this.social.email) {
        this.listing.contactDetails.push({ contacttypeid: 3, contactdetail: this.social.email })
      }
      if (this.social.website) {
        this.listing.contactDetails.push({ contacttypeid: 4, contactdetail: this.social.website })
      }
      if (this.social.facebook) {
        this.listing.contactDetails.push({ contacttypeid: 5, contactdetail: this.social.facebook })
      }
      if (this.social.twitter) {
        this.listing.contactDetails.push({ contacttypeid: 6, contactdetail: this.social.twitter })
      }
      if (this.social.instagram) {
        this.listing.contactDetails.push({ contacttypeid: 7, contactdetail: this.social.instagram })
      }
      if (this.social.linkedIn) {
        this.listing.contactDetails.push({ contacttypeid: 8, contactdetail: this.social.linkedIn })
      }
      if (!valid) return

      if (this.tab === this.tabs.length - 1) {
        await updateListing.updateListing(this.listingID, this.listing)
          .then((response) => {
            if (response.status === 200) {
              this.snackColor = 'success'
              this.snackText = 'Saved'
              this.snackTimeout = 3000
              this.initialise(this.listingID)
              this.$router.push({ path: '/manage/listings' })
            } else {
              if (response.data?.errors) {
                const errors = []
                response.data.errors.forEach(element => {
                  errors.push(element.errorMessage)
                })
                this.snackColor = 'error'
                this.snackText = errors
                this.snackTimeout = 5000
              } else {
                this.snackColor = 'error'
                this.snackText = response
                this.snackTimeout = 5000
              }
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
      } else {
        await updateListing.updateListing(this.listingID, this.listing)
          .then((response) => {
            if (response.status === 200) {
              this.snackColor = 'success'
              this.snackText = 'Saved'
              this.snackTimeout = 3000
              this.tab++
            } else {
              if (response.data?.errors) {
                const errors = []
                response.data.errors.forEach(element => {
                  errors.push(element.errorMessage)
                })
                this.snackColor = 'error'
                this.snackText = errors
                this.snackTimeout = 5000
              } else {
                this.snackColor = 'error'
                this.snackText = response
                this.snackTimeout = 5000
              }
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error)
          })
      }
    },
    removeTag (item) {
      const index = this.listing.tags.findIndex(i => i.tagName === item.tagName)
      if (index >= 0) this.listing.tags.splice(index, 1)
    },
    removeSuperTag (item) {
      this.listing.superTag = null
    },
    saveBusinessHours (value) {
      this.listing.hoursOfBusiness = []
      this.listing.hoursOfBusiness = value
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Hours Confirmed'
      this.snackTimeout = 3000
    }
  }
}
</script>
