<template>
  <div>
    <div v-if="isLoading">
      <v-progress-linear indeterminate color="green"></v-progress-linear>
    </div>
    <div v-else>
      <v-file-input
        class="my-4"
        v-model="uploadObject"
        :accept="fileType"
        :label="label"
        truncate-length="125"
        :rules="imageRules"
        clearable
      />
    </div>
    <v-btn @click="submit" class="float-right" :disabled="uploadObject.name === undefined" >submit</v-btn>
  </div>
</template>

<script>
import S3 from 'aws-s3'

export default {
  data () {
    return {
      isLoading: false,
      uploadObject: { name: this.obj[this.fieldName] },
      objectUrl: null,
      imageRules: [
        value => !value || value.size < 4000000 || 'File size should be less than 4 MB!'
      ],
      baseURL: 'https://frogv3.s3.eu-west-2.amazonaws.com',
      config: {
        bucketName: 'frogv3',
        dirName: this.directory,
        region: 'eu-west-2',
        accessKeyId: process.env.VUE_APP_AWS_KEY,
        secretAccessKey: process.env.VUE_APP_AWS_SECRET_KEY,
        s3Url: 'https://frogv3.s3.eu-west-2.amazonaws.com'
      }
    }
  },
  computed: {
    S3Client () {
      return new S3(this.config)
    },
    newFileName () {
      const filename = this.uploadObject.name
      return `${Math.random().toString().slice(2, 5)}-${filename.split('.').slice(0, -1).join('.')}`
    },
    imageUrl () {
      return `${this.baseURL}/${this.directory}/${this.newFileName}`
    }
  },
  methods: {
    uploadFile () {
      const file = this.uploadObject
      this.isLoading = true
      this.S3Client
        .uploadFile(file, this.newFileName).finally(() => {
          this.isLoading = false
          const fileExtension = file.type.split('/')[1]
          this.obj[this.fieldName] = `${this.imageUrl}.${fileExtension}`
          this.$emit('imageUploaded', this.obj[this.fieldName])
        })
    },

    submit (file) {
      this.uploadFile()
    }

  },
  props: ['fieldName', 'obj', 'directory', 'label', 'fileType']

}
</script>
<style lang="sass" scoped>
.image-container
  display: inline-block

.image-preview
  display: block
  max-height: 229px
  max-width: 100%
</style>
