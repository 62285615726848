<template>
<div>
  <v-row>
    <v-col cols="12">
      <v-switch v-model="disabled" label="Enable Business Hours"></v-switch>
    </v-col>
    <v-col cols="12">
      <v-checkbox v-model="sevenDays" label="7 Days" v-if="disabled === true"></v-checkbox>
    </v-col>
  </v-row>
  <div v-if="disabled === true">
    <v-row>
      <v-col cols="12" sm="4">
        <v-switch v-model="enableMonday" label="Monday"></v-switch>
      </v-col>
      <v-col cols="12" sm="4" v-if="enableMonday === true">
        <v-menu ref="openMonday" v-model="openHours.monday.timeOpen" :close-on-content-click="false" :nudge-right="40" :return-value.sync="openHours.monday.openTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="openHours.monday.openTime" label="Open Time" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="openHours.monday.timeOpen" v-model="openHours.monday.openTime" full-width @click:minute="$refs.openMonday.save(openHours.monday.openTime)"></v-time-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" v-if="enableMonday === true">
        <v-menu ref="closeMonday" v-model="openHours.monday.timeClose" :close-on-content-click="false" :nudge-right="40" :return-value.sync="openHours.monday.closeTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="openHours.monday.closeTime" label="Closing Time" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="openHours.monday.timeClose" v-model="openHours.monday.closeTime" full-width @click:minute="$refs.closeMonday.save(openHours.monday.closeTime)"></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4">
        <v-switch v-model="enableTuesday" label="Tuesday"></v-switch>
      </v-col>
      <v-col cols="12" sm="4" v-if="enableTuesday === true">
        <v-menu ref="openTuesday" v-model="openHours.tuesday.timeOpen" :close-on-content-click="false" :nudge-right="40" :return-value.sync="openHours.tuesday.openTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="openHours.tuesday.openTime" label="Open Time" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="openHours.tuesday.timeOpen" v-model="openHours.tuesday.openTime" full-width @click:minute="$refs.openTuesday.save(openHours.tuesday.openTime)"></v-time-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" v-if="enableTuesday === true">
        <v-menu ref="closeTuesday" v-model="openHours.tuesday.timeClose" :close-on-content-click="false" :nudge-right="40" :return-value.sync="openHours.tuesday.closeTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="openHours.tuesday.closeTime" label="Closing Time" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="openHours.tuesday.timeClose" v-model="openHours.tuesday.closeTime" full-width @click:minute="$refs.closeTuesday.save(openHours.tuesday.closeTime)"></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4">
        <v-switch v-model="enableWednesday" label="Wednesday"></v-switch>
      </v-col>
      <v-col cols="12" sm="4" v-if="enableWednesday === true">
        <v-menu ref="openWednesday" v-model="openHours.wednesday.timeOpen" :close-on-content-click="false" :nudge-right="40" :return-value.sync="openHours.wednesday.openTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="openHours.wednesday.openTime" label="Open Time" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="openHours.wednesday.timeOpen" v-model="openHours.wednesday.openTime" full-width @click:minute="$refs.openWednesday.save(openHours.wednesday.openTime)"></v-time-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" v-if="enableWednesday === true">
        <v-menu ref="closeWednesday" v-model="openHours.wednesday.timeClose" :close-on-content-click="false" :nudge-right="40" :return-value.sync="openHours.wednesday.closeTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="openHours.wednesday.closeTime" label="Closing Time" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="openHours.wednesday.timeClose" v-model="openHours.wednesday.closeTime" full-width @click:minute="$refs.closeWednesday.save(openHours.wednesday.closeTime)"></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4">
        <v-switch v-model="enableThursday" label="Thursday"></v-switch>
      </v-col>
      <v-col cols="12" sm="4" v-if="enableThursday === true">
        <v-menu ref="openThursday" v-model="openHours.thursday.timeOpen" :close-on-content-click="false" :nudge-right="40" :return-value.sync="openHours.thursday.openTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="openHours.thursday.openTime" label="Open Time" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="openHours.thursday.timeOpen" v-model="openHours.thursday.openTime" full-width @click:minute="$refs.openThursday.save(openHours.thursday.openTime)"></v-time-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" v-if="enableThursday === true">
        <v-menu ref="closeThursday" v-model="openHours.thursday.timeClose" :close-on-content-click="false" :nudge-right="40" :return-value.sync="openHours.thursday.closeTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="openHours.thursday.closeTime" label="Closing Time" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="openHours.thursday.timeClose" v-model="openHours.thursday.closeTime" full-width @click:minute="$refs.closeThursday.save(openHours.thursday.closeTime)"></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4">
        <v-switch v-model="enableFriday" label="Friday"></v-switch>
      </v-col>
      <v-col cols="12" sm="4" v-if="enableFriday === true">
        <v-menu ref="openFriday" v-model="openHours.friday.timeOpen" :close-on-content-click="false" :nudge-right="40" :return-value.sync="openHours.friday.openTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="openHours.friday.openTime" label="Open Time" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="openHours.friday.timeOpen" v-model="openHours.friday.openTime" full-width @click:minute="$refs.openFriday.save(openHours.friday.openTime)"></v-time-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" v-if="enableFriday === true">
        <v-menu ref="closeFriday" v-model="openHours.friday.timeClose" :close-on-content-click="false" :nudge-right="40" :return-value.sync="openHours.friday.closeTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="openHours.friday.closeTime" label="Closing Time" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="openHours.friday.timeClose" v-model="openHours.friday.closeTime" full-width @click:minute="$refs.closeFriday.save(openHours.friday.closeTime)"></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4">
        <v-switch v-model="enableSaturday" label="Saturday"></v-switch>
      </v-col>
      <v-col cols="12" sm="4" v-if="enableSaturday === true">
        <v-menu ref="openSaturday" v-model="openHours.saturday.timeOpen" :close-on-content-click="false" :nudge-right="40" :return-value.sync="openHours.saturday.openTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="openHours.saturday.openTime" label="Open Time" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="openHours.saturday.timeOpen" v-model="openHours.saturday.openTime" full-width @click:minute="$refs.openSaturday.save(openHours.saturday.openTime)"></v-time-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" v-if="enableSaturday === true">
        <v-menu ref="closeSaturday" v-model="openHours.saturday.timeClose" :close-on-content-click="false" :nudge-right="40" :return-value.sync="openHours.saturday.closeTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="openHours.saturday.closeTime" label="Closing Time" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="openHours.saturday.timeClose" v-model="openHours.saturday.closeTime" full-width @click:minute="$refs.closeSaturday.save(openHours.saturday.closeTime)"></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4">
        <v-switch v-model="enableSunday" label="Sunday"></v-switch>
      </v-col>
      <v-col cols="12" sm="4" v-if="enableSunday === true">
        <v-menu ref="openSunday" v-model="openHours.sunday.timeOpen" :close-on-content-click="false" :nudge-right="40" :return-value.sync="openHours.sunday.openTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="openHours.sunday.openTime" label="Open Time" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="openHours.sunday.timeOpen" v-model="openHours.sunday.openTime" full-width @click:minute="$refs.openSunday.save(openHours.sunday.openTime)"></v-time-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" v-if="enableSunday === true">
        <v-menu ref="closeSunday" v-model="openHours.sunday.timeClose" :close-on-content-click="false" :nudge-right="40" :return-value.sync="openHours.sunday.closeTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="openHours.sunday.closeTime" label="Closing Time" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="openHours.sunday.timeClose" v-model="openHours.sunday.closeTime" full-width @click:minute="$refs.closeSunday.save(openHours.sunday.closeTime)"></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
  <v-row>
    <v-col cols="12">
      <v-btn :disabled="disabled === false" @click="confirmHours">Confirm Hours</v-btn>
    </v-col>
  </v-row>
</div>
</template>

<script>
export default {
  name: 'OpenHours',
  props: ['enableHours', 'hoursOfBusiness'],
  data () {
    return {
      time: null,
      timeOpen: false,
      openTime: '',
      closeTime: '',
      enableMonday: true,
      enableTuesday: true,
      enableWednesday: true,
      enableThursday: true,
      enableFriday: true,
      enableSaturday: false,
      enableSunday: false,
      disabled: true,
      sevenDays: false,
      isOpen: false,
      openHours: {
        monday: { timeOpen: false, timeClose: false, openTime: '09:00', closeTime: '17:00' },
        tuesday: { timeOpen: false, timeClose: false, openTime: '09:00', closeTime: '17:00' },
        wednesday: { timeOpen: false, timeClose: false, openTime: '09:00', closeTime: '17:00' },
        thursday: { timeOpen: false, timeClose: false, openTime: '09:00', closeTime: '17:00' },
        friday: { timeOpen: false, timeClose: false, openTime: '09:00', closeTime: '17:00' },
        saturday: { timeOpen: false, timeClose: false, openTime: null, closeTime: null },
        sunday: { timeOpen: false, timeClose: false, openTime: null, closeTime: null }
      }
    }
  },
  mounted () {
    Object.keys(this.hoursOfBusiness).forEach(key => {
      switch (this.hoursOfBusiness[key].dayofWeek) {
        case 'Monday':
          this.enableMonday = this.hoursOfBusiness[key].open
          if (this.hoursOfBusiness[key].openTime === 'Closed' || this.hoursOfBusiness[key].closeTime === 'Closed') {
            this.openHours.monday.openTime = null
            this.openHours.monday.openTime = null
          } else {
            this.openHours.monday.openTime = this.hoursOfBusiness[key].openTime
            this.openHours.monday.closeTime = this.hoursOfBusiness[key].closeTime
          }
          break
        case 'Tuesday':
          this.enableTuesday = this.hoursOfBusiness[key].open
          if (this.hoursOfBusiness[key].openTime === 'Closed' || this.hoursOfBusiness[key].closeTime === 'Closed') {
            this.openHours.tuesday.openTime = null
            this.openHours.tuesday.openTime = null
          } else {
            this.openHours.tuesday.openTime = this.hoursOfBusiness[key].openTime
            this.openHours.tuesday.closeTime = this.hoursOfBusiness[key].closeTime
          }
          break
        case 'Wednesday':
          this.enableWednesday = this.hoursOfBusiness[key].open
          if (this.hoursOfBusiness[key].openTime === 'Closed' || this.hoursOfBusiness[key].closeTime === 'Closed') {
            this.openHours.wednesday.openTime = null
            this.openHours.wednesday.openTime = null
          } else {
            this.openHours.wednesday.openTime = this.hoursOfBusiness[key].openTime
            this.openHours.wednesday.closeTime = this.hoursOfBusiness[key].closeTime
          }
          break
        case 'Thursday':
          this.enableThursday = this.hoursOfBusiness[key].open
          if (this.hoursOfBusiness[key].openTime === 'Closed' || this.hoursOfBusiness[key].closeTime === 'Closed') {
            this.openHours.thursday.openTime = null
            this.openHours.thursday.openTime = null
          } else {
            this.openHours.thursday.openTime = this.hoursOfBusiness[key].openTime
            this.openHours.thursday.closeTime = this.hoursOfBusiness[key].closeTime
          }
          break
        case 'Friday':
          this.enableFriday = this.hoursOfBusiness[key].open
          if (this.hoursOfBusiness[key].openTime === 'Closed' || this.hoursOfBusiness[key].closeTime === 'Closed') {
            this.openHours.friday.openTime = null
            this.openHours.friday.openTime = null
          } else {
            this.openHours.friday.openTime = this.hoursOfBusiness[key].openTime
            this.openHours.friday.closeTime = this.hoursOfBusiness[key].closeTime
          }
          break
        case 'Saturday':
          this.enableSaturday = this.hoursOfBusiness[key].open
          if (this.hoursOfBusiness[key].openTime === 'Closed' || this.hoursOfBusiness[key].closeTime === 'Closed') {
            this.openHours.saturday.openTime = null
            this.openHours.saturday.openTime = null
          } else {
            this.openHours.saturday.openTime = this.hoursOfBusiness[key].openTime
            this.openHours.saturday.closeTime = this.hoursOfBusiness[key].closeTime
          }
          break
        case 'Sunday':
          this.enableSunday = this.hoursOfBusiness[key].open
          if (this.hoursOfBusiness[key].openTime === 'Closed' || this.hoursOfBusiness[key].closeTime === 'Closed') {
            this.openHours.sunday.openTime = null
            this.openHours.sunday.openTime = null
          } else {
            this.openHours.sunday.openTime = this.hoursOfBusiness[key].openTime
            this.openHours.sunday.closeTime = this.hoursOfBusiness[key].closeTime
          }
          break
        default:
          // eslint-disable-next-line
          console.warn('No matching hours found')
      }
    })
  },
  watch: {
    sevenDays: function (val) {
      if (val === true) {
        this.enableMonday = true
        this.enableTuesday = true
        this.enableWednesday = true
        this.enableThursday = true
        this.enableFriday = true
        this.enableSaturday = true
        this.enableSunday = true
      } else {
        this.enableMonday = false
        this.enableTuesday = false
        this.enableWednesday = false
        this.enableThursday = false
        this.enableFriday = false
        this.enableSaturday = false
        this.enableSunday = false
      }
    },
    enableHours: function (val) {
      if (val === true) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    }
  },
  methods: {
    confirmHours () {
      const openHours = this.openHours
      let hoursOfBusiness = []
      hoursOfBusiness = [
        {
          dayofWeek: 'Monday',
          openTime: openHours.monday.openTime,
          closeTime: openHours.monday.closeTime,
          open: this.enableMonday
        },
        {
          dayofWeek: 'Tuesday',
          openTime: openHours.tuesday.openTime,
          closeTime: openHours.tuesday.closeTime,
          open: this.enableTuesday
        },
        {
          dayofWeek: 'Wednesday',
          openTime: openHours.wednesday.openTime,
          closeTime: openHours.wednesday.closeTime,
          open: this.enableWednesday
        },
        {
          dayofWeek: 'Thursday',
          openTime: openHours.thursday.openTime,
          closeTime: openHours.thursday.closeTime,
          open: this.enableThursday
        },
        {
          dayofWeek: 'Friday',
          openTime: openHours.friday.openTime,
          closeTime: openHours.friday.closeTime,
          open: this.enableFriday
        },
        {
          dayofWeek: 'Saturday',
          openTime: openHours.saturday.openTime,
          closeTime: openHours.saturday.closeTime,
          open: this.enableSaturday
        },
        {
          dayofWeek: 'Sunday',
          openTime: openHours.sunday.openTime,
          closeTime: openHours.sunday.closeTime,
          open: this.enableSunday
        }
      ]
      this.$emit('hoursChanged', hoursOfBusiness)
    }
  }
}
</script>
